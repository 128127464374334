import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Icon,
  Image,
  Link,
  Text,
} from "@chakra-ui/react"
import * as React from "react"
import { FaMapPin } from "react-icons/fa"
import { PageProps } from "gatsby"
import GoogleMapReact from "google-map-react"

import Layout from "../components/layout"
import { Hours, DailyHours } from "../data/hours"
import BlockHeader from "../components/blockHeader"
import { getHoursFromDay } from "../utils"
import SEO from "../components/CoreUI/SEO"

const aLat = 35.59131616879065
const aLng = -82.5834620022733
const sLat = 35.37403985319242
const sLng = -83.2255699711645
const api_key = `AIzaSyCo9d9Tgm9MmgLjYn7uLBYb45q6Sc1rDFM`

function Location({ location }: PageProps): JSX.Element {
  const [dayOfWeek, setDayOfWeek] = React.useState<number>(null)

  React.useEffect(() => {
    const dow = new Date().getDay()
    setDayOfWeek(dow)
  }, [])

  const defaultProps = {
    center: {
      lat: (aLat + sLat) / 2,
      lng: (aLng + sLng) / 2,
    },
    zoom: 10,
  }

  return (
    <Layout>
      <SEO
        meta={{
          description:
            "Our offices and Design Studio are conveniently located off Patton Avenue in Asheville, North Carolina.",
          image: `${location.origin}/sundog-team-2022-630x205.png`,
          imageAlt: `The Sundog Homes team`,
        }}
        title="Location | Sundog Homes"
        url={location.href}
      />
      <Flex
        flexDir={{ base: `column`, lg: `row` }}
        h={{ lg: `calc(100vh - 4.5rem)` }}
        w="100vw"
      >
        <Box
          h={{ base: `45vh`, lg: `calc(100vh - 4.5rem)` }}
          w={{ base: `100vw`, lg: `50vw` }}
        >
          <GoogleMapReact
            bootstrapURLKeys={{ key: api_key }}
            defaultCenter={defaultProps.center}
            defaultZoom={defaultProps.zoom}
            options={{ fullscreenControl: false }}
          >
            <Box lat={aLat} lng={aLng} transform="translate(-36px, -36px)">
              <Icon as={FaMapPin} boxSize="48px" color="red.500" />
            </Box>
            <Box lat={sLat} lng={sLng} transform="translate(-36px, -36px)">
              <Icon as={FaMapPin} boxSize="48px" color="red.500" />
            </Box>
          </GoogleMapReact>
        </Box>
        <Flex
          align="center"
          flexDir="column"
          h={{ lg: `calc(100vh - 4.5rem)` }}
          justify="space-evenly"
          overflowY={{ lg: `scroll` }}
          py={{ base: 4, lg: 0 }}
          w={{ base: `100vw`, lg: `50vw` }}
        >
          <Box textAlign="center" w="100%">
            <Image
              alt="The Sundog Homes ring glpyh."
              mb={4}
              mx="auto"
              src="/logo/rings-flat-cropped.svg"
              w="75px"
            />
            <BlockHeader mx="auto" w="180px">
              Our Location
            </BlockHeader>
            <Text
              fontFamily="heading"
              fontSize={{ base: `4xl`, sm: `6xl` }}
              fontWeight="bold"
              mx="auto"
            >
              Sundog Homes
            </Text>
            <Flex
              direction={[`column`, null, `row`]}
              gap={8}
              justify="space-evenly"
              mx="auto"
              my={8}
            >
              <Box>
                <Text fontSize="xl" fontWeight="bold" mt={2}>
                  18 Regent Park Blvd, Suite C
                  <br />
                  Asheville, NC 28806
                  <br />
                  <Link
                    color="red.500"
                    fontSize="lg"
                    fontWeight="medium"
                    href="https://goo.gl/maps/VBuQgM5JQ5LXCxrFA"
                    mt={1}
                  >
                    Get Directions (Google Maps)
                  </Link>
                </Text>
              </Box>
              {/* <Divider mx="auto" my={8} w="75%" /> */}
              <Box>
                <Text fontSize="xl" fontWeight="bold" mt={2}>
                  714 W Main St, #300
                  <br />
                  Sylva, NC 28779
                  <br />
                  <Link
                    color="red.500"
                    fontSize="lg"
                    fontWeight="medium"
                    href="https://maps.app.goo.gl/g8FNSM44FiHweAvz6"
                    mt={1}
                  >
                    Get Directions (Google Maps)
                  </Link>
                </Text>
              </Box>
            </Flex>
          </Box>
          <Box textAlign="center" w="100%">
            <Heading as="h2" fontSize="4xl" fontWeight="bold">
              Hours
            </Heading>
            <Flex
              align="start"
              flexDir="column"
              fontSize="lg"
              fontWeight="medium"
              maxW="250px"
              mt={{ base: 2, sm: 6 }}
              mx="auto"
              w="100%"
            >
              {Hours.map((day: DailyHours, index: number) => {
                const isToday = dayOfWeek === index + 1
                const template = `%t1%m1 - %t2%m2`
                return (
                  <Flex
                    bg={isToday ? `red.50` : null}
                    color={isToday ? `red.500` : null}
                    justify="space-between"
                    px={1}
                    rounded="sm"
                    w="100%"
                  >
                    <Text>{day.day}</Text>
                    <Text>{getHoursFromDay(day, template)}</Text>
                  </Flex>
                )
              })}
            </Flex>
          </Box>
          <Flex
            align="center"
            bg="gray.100"
            flexDir="column"
            my={8}
            p={4}
            rounded="lg"
            w="75%"
          >
            <Text fontSize="2xl" fontWeight="bold">
              Need Help?
            </Text>
            <Text
              d={{ base: `none`, md: `block` }}
              fontSize="lg"
              fontWeight="semibold"
            >
              Give us a call:{" "}
              <Link color="red.500" fontSize="lg" href="tel:8287745720">
                (828) 774-5720
              </Link>
            </Text>
            <Link href="tel:8287745720" _hover={{ textDecor: `none` }}>
              <Button
                colorScheme="red"
                d={{ base: `block`, md: `none` }}
                my={2}
                size="lg"
              >
                Give Us a Call
              </Button>
            </Link>
          </Flex>
        </Flex>
      </Flex>
    </Layout>
  )
}

export default Location
